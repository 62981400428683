/* eslint-disable import/no-named-default */
/* eslint-disable camelcase */
/* eslint-disable import/no-unresolved */
import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import BasicModal from '@cleverrealestate/clever-components-v2/dist/components/BasicModal';
import PageContainer from '@cleverrealestate/clever-components-v2/dist/components/PageContainer';
import styles from '@cleverrealestate/clever-components-v2/dist/components/PageContainer/PageContainer.scss';
import {
  useUserIPGrabber,
} from '@cleverrealestate/clever-helpers';
import SEO from '../components/Seo';
import Layout from '../components/layout';
import useGlobalCTAClick from '../utils/useGlobalCtaClick';
import useGlobalTOCHandler from '../utils/toc.utils';
import { useCheckForEditorCookie } from '../utils/publishing-utils';
import renderContent from '../utils/inline-utils-v3';

const ContentPageTemplate = ({ pageContext }) => {
  useUserIPGrabber();
  const {
    // Display Values
    authors,
    structured_data,
    breadcrumbs,
    isAutoBreadcrumbs,
    content_width,
    bottom_cta,
    nav_bar_type,
    // allInlines,
    // Content Values
    title,
    renderContext,
    modified_at_formatted,
    // SEO Values
    slug,
    share_slug,
    canonical_slug,
    created_at_iso,
    modified_at_iso,
    no_index,
    meta_title,
    meta_description,
    article_image,
    extraMeta,
    cosmic_edit_path,
    hide_authors,
    hide_share_button,
  } = pageContext;
  useGlobalTOCHandler();
  const isEditor = useCheckForEditorCookie();
  const [modalOpen, setModalOpen] = useGlobalCTAClick();
  const publishDateMeta = [
    {
      name: 'article:published_time',
      content: created_at_iso,
    },
    {
      name: 'article:modified_time',
      content: modified_at_iso,
    },
    {
      name: 'og:updated_time',
      content: modified_at_iso,
    },
  ];

  return (
    <Layout
      colorTransitionStart={-1}
      shadowTransitionStart={50}
      colorTransitionLength={1}
      shadowTransitionLength={125}
      isWhite={nav_bar_type === 'Main Site'}
      showBlogNav={nav_bar_type === 'Blog'}
    >
      <SEO
        pathname={slug}
        title={meta_title}
        description={meta_description}
        canonical={canonical_slug || slug}
        publishDateMeta={publishDateMeta}
        breadcrumbs={isAutoBreadcrumbs ? breadcrumbs : null}
        structuredData={structured_data}
        noIndex={no_index}
        image={article_image.url}
        imageHeight={article_image.height}
        imageWidth={article_image.width}
        meta={extraMeta}
        addWebsiteName={false}
      />
      <PageContainer
        title={title}
        breadcrumbs={!isAutoBreadcrumbs ? breadcrumbs : []}
        author={authors}
        publishDate={modified_at_formatted}
        contentWidth={content_width || 'default'}
        bottomCTA={bottom_cta}
        slug={share_slug}
        isEditor={isEditor}
        cosmic_edit_path={cosmic_edit_path}
        hideAuthors={hide_authors}
        hideShareButton={hide_share_button}
      >
        <article id="article-content" className={styles.mainContent}>
          {renderContent(renderContext.html, renderContext.context)}
        </article>
        {
          modalOpen ? createPortal((
            <BasicModal
              closeDialog={() => setModalOpen(false)}
            />
          ), document.body) : null
        }
      </PageContainer>
    </Layout>
  );
};

ContentPageTemplate.propTypes = {
  pageContext: PropTypes.any,
};

ContentPageTemplate.defaultProps = {
  pageContext: {},
};

export default ContentPageTemplate;

/* eslint-disable import/prefer-default-export */
import { useState, useEffect } from 'react';
import { getCookie } from '@cleverrealestate/clever-helpers';

export const useCheckForEditorCookie = () => {
  const [hasEditorCookie, setHasEditorCookie] = useState(false);

  useEffect(() => {
    const editorCookie = getCookie('X-Kita-Pub');
    if (editorCookie !== null && editorCookie === 'happy-doggie-biscuit') {
      setHasEditorCookie(true);
    }
  }, []);

  return hasEditorCookie;
};
